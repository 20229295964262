<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                        <b-col lg="6" sm="12">
                            <ValidationProvider vid="org_id" name="Organization" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="org_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="forward.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="true"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback d-block">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Office Type" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="office_type_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('org_pro.office_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="forward.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="!$store.state.Auth.activeRoleId"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Office" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('org_pro.office_namel')}} <span class="text-danger">*</span>
                                </template>
                                <v-select name="region_office_id"
                                  v-model="forward.office_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= officeList
                                  :placeholder="$t('globalTrans.select')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :filter-by="myFilter"
                                  :disabled="!$store.state.Auth.activeRoleId"
                                />
                                <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Designation" vid="designation_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="designation_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('budget.designation') }} <span class="text-danger">*</span>
                                </template>
                            <b-form-select
                                plain
                                v-model="forward.designation_id"
                                :options="designationList"
                                id="designation_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Officer" vid="receiver_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="receiver_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('budget.officer') }} <span class="text-danger">*</span>
                                </template>
                            <b-form-select
                                plain
                                v-model="forward.receiver_id"
                                id="receiver_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :options="officerList.filter(item => item.designation_id === forward.designation_id)"
                            >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{ officerLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Comment" vid="comment" rules="required|max:200">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="comment"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('budget.comment') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-textarea
                                    plain
                                    v-model="forward.comment"
                                    id="comment"
                                    placeholder="Maximum 200 word.."
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-textarea>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-12" sm="12">
                            <div class="text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { proposalTechnicalEvaluationForward } from '../../api/routes'
const today = new Date().toISOString().substr(0, 10)

export default {
  props: ['id', 'assignDesignationList'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.$t('globalTrans.save'),
      forward: {
        proposal_sub_main_id: this.id,
        org_id: 0,
        office_type_id: 0,
        office_id: null,
        designation_id: 0,
        sender_id: this.$store.state.Auth.authUser.user_id,
        receiver_id: 0,
        forward_date: today,
        comment: ''
      },
      officerLoading: false,
      designationList: [],
      allOfficerList: [],
      officeList: [],
      officerList: [],
      officeTypeList: [],
      orgList: this.$store.state.commonObj.organizationProfileList,
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      }
    }
  },
  computed: {
  },
  mounted () {
    this.forward.org_id = 6
  },
  watch: {
    'forward.org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.officeTypeList = this.getOfficeTypeList(newValue)
      } else {
        this.officeTypeList = []
      }
    },
    'forward.office_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeList = this.getOfficeList(newVal)
      }
    },
    'forward.office_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.designationList = this.getDesignationList(newValue)
      } else {
        this.designationList = []
      }
    },
    'forward.designation_id': function (newValue) {
      this.getOfficerList(newValue)
    }
  },
  methods: {
    getRegionInfoData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
        this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }

        result = await RestApi.postData(agriResearchServiceBaseUrl, proposalTechnicalEvaluationForward, this.forward)
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        this.loading = false

        if (result.success) {
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$bvModal.hide('modal-1')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === orgId)
      return officeTypeList
    },
    getOfficeList (officeTypeId = null) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(office => office.office_type_id === officeTypeId)
      }
      return officeList
    },
    getDesignationList (officeId) {
      const assignDesignationList = this.assignDesignationList.filter(item => item.office_id === officeId)
      const designationList = assignDesignationList.map(item => {
        const designation = this.$store.state.commonObj.designationList.find(obj => obj.value === item.designation_id)
        return designation
      })
      return designationList
    },
    async getOfficerList () {
      if (this.forward.org_id > 0) {
        this.officerList = []
        this.officerLoading = true
        const params = { org_id: parseInt(this.forward.org_id) }
        const response = await RestApi.getData(authServiceBaseUrl, 'user/list-by-designation', params)
        if (response.success) {
          this.allOfficerList = response.data
          this.officerList = this.allOfficerList.map(item => ({
              value: item.user_id,
              text: this.$i18n.locale === 'bn' ? item.name_bn : item.name,
              text_en: item.name,
              text_bn: item.name_bn,
              designation_id: item.designation_id
          }))
          this.officerLoading = false
        } else {
          this.officerLoading = false
          this.officerList = []
        }
      }
    }
  }
}
</script>
